import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
  title: "Business Head",
  type: "Full Time",
  level: "Mid Level",

  description:
    "Aakash Group is launching Aakash Snacks, an exciting new venture in the Nepali FMCG market. We are seeking a highly motivated and experienced Business Head to lead this initiative. The ideal candidate will possess a strong entrepreneurial spirit, a deep understanding of the Nepali snack market, and a proven ability to build brands from the ground up. This role requires a strategic thinker with a hands-on approach, a passion for innovation, and a commitment to achieving ambitious growth targets.",

  responsibilities: [
    "Market Exploration and Analysis:",
"Conduct in-depth market research to identify emerging trends, consumer preferences, and untapped opportunities within the Nepali snack market.",
"Analyze competitor activities, pricing strategies, and distribution channels to identify competitive advantages for Aakash Snacks.",
"Develop detailed market entry strategies for new product categories.",
"Brand Building and Value Creation:",
"Develop and implement a compelling brand strategy for Aakash Snacks, establishing a strong brand identity, positioning, and messaging.",
"Build brand awareness and loyalty through effective marketing campaigns across various channels (traditional and digital).",
"Enhance brand value by focusing on product quality, innovation, and customer experience.",
"Strategic Implementation and Execution:",
"Develop and execute business plans, sales strategies, and marketing campaigns with a focus on minimizing errors and maximizing efficiency.",
"Monitor performance against key metrics and make data-driven adjustments to strategies as needed.",
"Ensure effective coordination between different departments (sales, marketing, operations, finance) to achieve business objectives.",
"Vendor Relationship Management and Distribution:",
"Establish and maintain strong relationships with key vendors, suppliers, and distributors across Nepal.",
"Develop and optimize the distribution network to ensure efficient product availability and reach across all target markets.",
"Negotiate favorable contracts with vendors and distributors.",
"Cross-Functional Collaboration and Leadership:",
"Lead and motivate a high-performing team, fostering a culture of innovation, collaboration, and accountability.",
"Work closely with other departments within the Aakash Group to leverage synergies and resources.",
"Effectively communicate with stakeholders at all levels, including senior management, team members, vendors, and customers.",
"Strategic Thinking and Innovation:",
"Contribute innovative ideas and approaches to drive business growth and market leadership.",
"Analyze market trends and identify opportunities for new product development and market expansion.",
"Adopt both `bottom-up` and `top-down` approaches to understand the perspectives of clients, customers, and owners, ensuring alignment of strategies and objectives.",
"Software Proficiency:",
"Demonstrate knowledge of relevant software used in the FMCG industry, such as:",
"Enterprise Resource Planning (ERP) systems",
"Customer Relationship Management (CRM) systems (e.g., Zoho CRM)",
"Sales Force Automation (SFA) tools",
"Business intelligence and analytics tools",
"Continuous Improvement and Learning:",
"Demonstrate a strong willingness to learn and experiment with new ideas and approaches to improve business performance.",
"Stay up-to-date with industry best practices and emerging trends.",
],

  requirements: [
    "Bachelor's degree in Business Administration, Marketing, or a related field; MBA preferred.",
    "Minimum 3 years of proven experience in the FMCG industry, with a focus on sales marketing, or business development.",
    "Demonstrated ability to build brands and drive market share growth.",
    "Strong understanding of the Nepali snack market and distribution landscape.",
    "Excellent communication, interpersonal, and negotiation skills.",
    "Strong analytical, problem-solving, and decision-making skills.",
    "Proficiency in Microsoft Office and relevant business software.",
    "Fluency in Nepali and English (both written and spoken).",
  ],

  noOfOpenings: 1,
};

const BusinessHead = () => {
  return <CareerDetailPage careerDetails={careerDetails} />;
};

export default BusinessHead;
